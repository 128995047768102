import * as React from 'react'
import {Panel} from 'react-bootstrap'

const I18n = window.I18n

export function FailurePanel() {
    return <Panel bsStyle="danger">
        <Panel.Heading>
            <Panel.Title componentClass="h3">
                {I18n.t('donations.home.stripe_form.payment_rejected_html')}
            </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
            {I18n.t('donations.home.stripe_form.check_your_data_and_try_again')}
        </Panel.Body>
    </Panel>
}
