import rootReducer from './reducers'
import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit'
import {useDispatch} from 'react-redux'
import {analyticsMiddleware} from './middleware/analytics'
import {gtmMiddleware} from "./middleware/gtm";

const store = configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware(), analyticsMiddleware, gtmMiddleware]
})

export type AppDispatch = typeof store.dispatch
export const useDonationsAppDispatch = () => useDispatch<AppDispatch>()

export {store}
