import * as React from 'react'
import {Col, Form, Row} from 'react-bootstrap'
import AmountSelector from './AmountSelector'
import {PledgeLabel} from './PledgeLabel'
import PaymentMethodSelector from './PaymentMethodSelector'
import {useSelector} from 'react-redux'
import DataLoader from './DataLoader'
import {EmailInput} from './EmailInput'
import {CurrencyAlert} from './CurrencyAlert'
import {ServerErrorModal} from './ServerErrorModal'
import {AdditionalPaymentMethods} from './AdditionalPaymentMethods'
import {CardPaymentButton} from './payment-buttons/CardPaymentButton'
import {SepaButton} from './payment-buttons/SepaButton'
import {PaypalButton} from './payment-buttons/PaypalButton'

const I18n = window.I18n

export default function DonationsForm() {
    const paymentMethod = useSelector(s => s.donations.paymentMethod?.id)
    const isMonthlyAllowed = useSelector(s => s.donations.paymentMethod?.monthlyAllowed)
    const singleValues = useSelector(s => s.donations.singleValues)
    const monthlyValues = useSelector(s => s.donations.monthlyValues)

    return <DataLoader>
        <Form componentClass='div' horizontal className='stripe-v3'>

            <CurrencyAlert/>

            <EmailInput/>

            {isMonthlyAllowed && <Row>
              <Col componentClass={PledgeLabel} md={5} xs={12}>
                  {I18n.t('donations.home.stripe_form_v2.monthly_subscription')}
              </Col>
              <Col md={6} xs={12}>
                <AmountSelector period='month' choices={monthlyValues}/>
              </Col>
            </Row>}

            <Row>
                <Col componentClass={PledgeLabel} md={5} xs={12}>
                    {I18n.t('donations.home.stripe_form_v2.one_time_subscription')}
                </Col>
                <Col md={6} xs={12}>
                    <AmountSelector period='single' choices={singleValues}/>
                </Col>
            </Row>

            <Row>
                <Col md={6} mdOffset={3} xs={12} className='payment-selector'>
                    <PaymentMethodSelector/>
                </Col>

                <Col md={6} mdOffset={3} xs={12}>
                    {(paymentMethod === 'stripe') && <CardPaymentButton/>}
                    {(paymentMethod === 'sepa') && <SepaButton/>}
                    {(paymentMethod === 'paypal') && <PaypalButton/>}
                </Col>
                {/* Masterpass, visa checkout, etc */}
                {(paymentMethod === 'stripe') && <AdditionalPaymentMethods/>}
            </Row>

        </Form>
        {/* Always hidden handle server side errors */}
        <ServerErrorModal/>
    </DataLoader>
}
