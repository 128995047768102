import * as React from 'react';
import {loadFormData} from '../../donations-store/slices/donations'
import {useDonationsAppDispatch} from '../../donations-store'
import {useDidMount} from 'beautiful-react-hooks'

/**
 * Wrapper component that loads data needed in Donations form
 * @constructor
 */
export default function DataLoader({children}: React.PropsWithChildren<{}>) {
    const dispatch = useDonationsAppDispatch();
    // Component initialization
    useDidMount(() => dispatch(loadFormData()));

    return <React.Fragment>{children}</React.Fragment>
}
