import React, { Fragment } from 'react'
import property from 'lodash/property'
import map from 'lodash/map'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal } from 'react-bootstrap'
import { acknowledgeError } from '../../donations-store/slices/donations'

const I18n = window.I18n

export function ServerErrorModal () {
  const serverError = useSelector(property('donations.serverError'))
  const {data, status} = serverError ? serverError : {}
  const dispatch = useDispatch()
  return <Modal show={Boolean(status)} onClose={() => dispatch(acknowledgeError())}>
    <Modal.Header closeButton onHide={close}>
      <Modal.Title>{I18n.t('donations.home.stripe_form_v2.server_error_title')} - Error code {status}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p dangerouslySetInnerHTML={{__html: I18n.t('donations.home.stripe_form_v2.server_error_body_html')}}/>
      {/* Errors reported in `error` array */}
      <Errors data={data}/>
    </Modal.Body>
    <Modal.Footer>
      <Button type='submit' bsStyle="danger" onClick={() => dispatch(acknowledgeError())}>OK</Button>
    </Modal.Footer>
  </Modal>
}

function Errors ({data}) {
  if (data && isArray(data.error)) {
    return <div>
      <p>Errors:</p>
      <ErrorList messages={data.error}/>
    </div>
  } else if (isObject((data))) { // Generic errors as key => [errors]
    return <div>
      {map(data, (errors, field) => (<Fragment key={field}>
        <p>{field}:</p>
        <ErrorList messages={errors}/>
      </Fragment>))}
    </div>
  } else { // Don't render
    return null
  }
}

ErrorList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string).isRequired
}

function ErrorList ({messages}) {
  return <ul>{messages.map(e => <li key={e}>{e}</li>)}</ul>
}
