import {RootState} from "../../../donations-store/reducers";
import {I18nLocale} from "../../../globals";
import {Currency, Period} from "../../../donations-store/slices/donations";

const I18n = window.I18n

export interface AnalyticsCampaign {
    campaign_id: number
    campaign: string
}

export const campaignSelector: (RootState) => AnalyticsCampaign = (state: RootState) => ({
    campaign_id: state.donations.campaign.id,
    campaign: state.donations.campaign.name,
})

export interface AnalyticsCheckout {
    email: string
    locale: I18nLocale
    amountCents: number
    amountCurrency: Currency
    period: Period
}

/**
 * Select payments params from state
 */
export const stateSelector: (RootState) => AnalyticsCheckout = (state: RootState) => ({
    email: state.donations.email,
    locale: I18n.locale,
    amountCents: state.donations.amount * 100,
    amountCurrency: state.donations.currency,
    period: state.donations.period
})
