import React, { Fragment } from 'react'
import makeAsyncScript from 'react-async-script'
import property from 'lodash/property'
import every from 'lodash/every'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import api from '../../lib/api'
import PropTypes from 'prop-types'
import { startCheckout } from '../../donations-store/slices/donations'

const Routes = window.Routes
const I18n = window.I18n
const ReactApp = window.ReactApp
// This is static and retrieved from stripe dashboard
const MASTERPASS_CHECKOUT_ID = 'ca0673c35c0e4bc8b3b5824753f55d30'

function masterpassCheckout (paymentId, amount, currency, masterpass) {
  // Redirect url must match with the one inserted in stripe dashboard
  const callbackUrl = window.location.origin.match(/localhost/) || window.location.origin.match(/lvh/)
    ? `https://aleteia-subscriptions.ngrok.io${Routes.donations_masterpass_path({
      cartId: paymentId,
      locale: I18n.locale
    })}`
    : Routes.donations_masterpass_url({cartId: paymentId, locale: I18n.locale})

  masterpass.checkout({
    checkoutId: MASTERPASS_CHECKOUT_ID,
    allowedCardTypes: ['master, amex, visa'],
    amount: Number(amount).toFixed(2),
    currency: currency.toUpperCase(),
    cartId: paymentId,
    callbackUrl: callbackUrl
  })
}

function masterpassScriptUrl () {
  return ReactApp.stripeStaging ? 'https://sandbox.masterpass.com/integration/merchant.js' : 'https://masterpass.com/integration/merchant.js'
}

MasterPassButtonWrapper.propTypes = {
  masterpass: PropTypes.shape({
    checkout: PropTypes.func.isRequired
  })
}

function MasterPassButtonWrapper ({masterpass}) {
  const email = useSelector(property('donations.email'))
  const campaign = useSelector(property('donations.campaign.name'))
  const period = useSelector(property('donations.period'))
  const dispatch = useDispatch()
  const masterpassParams = useSelector(state => ({
    email: email,
    locale: I18n.locale,
    amountCents: state.donations.amount * 100,
    amountCurrency: state.donations.currency,
    period: state.donations.period
  }), shallowEqual)

  const sendPayment = () => {
    dispatch(startCheckout({
      source: 'MasterPass',
      amount: masterpassParams.amountCents / 100,
      currency: masterpassParams.currency,
      period: masterpassParams.period,
      loading: true
    }))
    api.post(window.Routes.donations_payment_intent_path(), {paymentRequest: {...masterpassParams, campaign: campaign}})
      .then(data => masterpassCheckout(data.id, data.amount, data.currency, masterpass))
  }

  return <Fragment>
    <input type='image' disabled={!every(masterpassParams) || period !== 'single'}
           src='https://static.masterpass.com/dyn/img/btn/global/mp_chk_btn_147x034px.svg'
           alt='Masterpass Checkout' onClick={sendPayment}/>
  </Fragment>
}

export const MasterPassButton = makeAsyncScript(
  masterpassScriptUrl,
  {globalName: 'masterpass'}
)(MasterPassButtonWrapper)
