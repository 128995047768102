import * as React from 'react'
import {useApi} from '../../../lib/api'
import {Period, startCheckout} from '../../../donations-store/slices/donations'
import isEmpty from 'lodash/isEmpty'
import {Fragment} from 'react'
import {Alert, Button} from 'react-bootstrap'
import {FaIcon} from '../FaIcon'
import includes from 'lodash/includes'
import {useAmount, useCampaign, useCurrency, usePeriod} from '../../../donations-store/hooks'
import {useDonationsAppDispatch} from "../../../donations-store";
import {Outcome} from "../../../donations-store/middleware/gtm";

const I18n = window.I18n

/**
 * Paypal locale map
 *
 * @type {{ar: string, pt: string, en: string, it: string, fr: string, pl: string, es: string}}
 */
const PAYPAL_LOCALES = {
    ar: 'ar_EG',
    es: 'es_ES',
    en: 'en_US',
    fr: 'fr_FR',
    it: 'it_IT',
    pl: 'pl_PL',
    pt: 'pt_BR',
}

/**
 * List of paypal unsupported currencies (among supported ones) for paypal
 *
 * @type {string[]}
 */
const PAYPAL_UNSUPPORTED_CURRENCIES = ['ARS', 'COP']

function supported(currency) {
    return !includes(PAYPAL_UNSUPPORTED_CURRENCIES, currency)
}

/**
 * Append GTM event payload to paypal redirection urls, notice that email is not available
 */
function appendPayloadToUri(url : string, amount: number, period: Period, outcome: Outcome) : string {
    const parsed = new URL(url)
    if (amount) parsed.searchParams.set('donation_amount', amount.toString())
    if (period) parsed.searchParams.set('donation_type', period == 'month' ? 'monthly' : 'unique')
    parsed.searchParams.set('result', outcome)
    return parsed.toString();
}

function PaypalCurrencyAlert() {
    const currency = useCurrency()
    // Ok currency is supported
    if (supported(currency)) return null
    // Show a warning
    return <Alert bsStyle="warning">
        <strong>{I18n.t('donations.warning')}</strong> {I18n.t('donations.paypal_currency_alert')}
    </Alert>
}

export function PaypalButton() {
    const currency = useCurrency()
    const amount = useAmount()
    const period = usePeriod()
    const campaign = useCampaign()
    // Get runtime info from backend
    const [{data: paypal = {}, loading}] = useApi(`/${I18n.locale}/paypal_info`)
    // button enabler
    const buttonEnabled = amount && currency && period && !loading && supported(currency)
    // const paypal locale
    const paypalLocale = PAYPAL_LOCALES[I18n.locale] || 'en_US'
    const dispatch = useDonationsAppDispatch()

    // Dispatch checkout start
    const onSubmit = () => {
        dispatch(startCheckout({
            source: 'Paypal',
            amount: amount,
            currency: currency,
            period: period
        }))
    }

    return <form acceptCharset="UTF-8" method="post" target='_blank' action={paypal.action} onSubmit={onSubmit}>

        {/* Coming from backend */}
        {!isEmpty((paypal)) && <Fragment>
          <input type="hidden" name="business" value={paypal.business}/>
          <input type="hidden" name="notify_url" value={paypal.notifyUrl}/>
          <input type="hidden" name="return" value={appendPayloadToUri(paypal.returnUrl, amount, period, 'fail')}/>
          <input type="hidden" name="cancel_return" value={appendPayloadToUri(paypal.cancelReturnUrl, amount, period, 'fail')}/>
        </Fragment>}

        {/* Static parameters */}
        <input type="hidden" name="lc" value={paypalLocale}/>
        <input type="hidden" name="quantity" value="1"/>
        <input type="hidden" name="item_name" value={I18n.t('donations.contribution_description')}/>
        <input type="hidden" name="no_shipping" value="1"/>
        <input type="hidden" name="no_note" value="1"/>
        <input type="hidden" name="charset" value="utf-8"/>
        <input type="hidden" name="address_override" value="0"/>
        <input type="hidden" name="bn" value="ActiveMerchant"/>
        <input type="hidden" name="custom" value={JSON.stringify({locale: I18n.locale, ...campaign})}/>

        {period === 'single' && <Fragment>
          <input type="hidden" name="cmd" value="_ext-enter"/>
          <input type="hidden" name="currency_code" value={currency}/>
          <input type="hidden" name="amount" value={amount}/>
          <input type="hidden" name="redirect_cmd" value="_xclick"/>
        </Fragment>}

        {period === 'month' && <Fragment>
          <input type="hidden" name="cmd" value="_xclick-subscriptions"/>
          <input type="hidden" name="currency_code" value={currency}/>
          <input type="hidden" name="src" value="1"/>
          <input type="hidden" name="sra" value="1"/>
          <input type="hidden" name="a3" value={amount}/>
          <input type="hidden" name="p3" value="1"/>
          <input type="hidden" name="t3" value="M"/>
        </Fragment>}

        <PaypalCurrencyAlert/>

        <Button type='submit' bsStyle="primary" bsSize="large" block disabled={!buttonEnabled}>
            {I18n.t('donations.home.stripe_form.donate_now')} <FaIcon name='paypal'/>
        </Button>
    </form>
}
