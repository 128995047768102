import * as React from 'react'
import {Col, ControlLabel} from 'react-bootstrap'
import {ColProps} from "react-bootstrap/lib/Col";

export function PledgeLabel({children, ...props}: React.PropsWithChildren<ColProps>) {
    return <Col {...props}>
        <ControlLabel style={{fontSize: '18px', marginBottom: '5px'}}>
            {children}
        </ControlLabel>
    </Col>
}
