import * as React from 'react'
import {Panel} from 'react-bootstrap'

const I18n = window.I18n

export function SuccessPanel() {
    return <Panel bsStyle="success">
        <Panel.Heading>
            <Panel.Title componentClass="h3">
                <strong>{I18n.t('donations.donor_mailer.thank_you.thank_you')}</strong>
                {' '}
                {I18n.t('donations.home.stripe_form.payment_submitted_title')}
            </Panel.Title>
        </Panel.Heading>
        <Panel.Body>
            {I18n.t('donations.home.stripe_form.you_will_receive_an_email')}
        </Panel.Body>
    </Panel>
}
