import { createMiddleware } from 'redux-beacon'
import GoogleAnalytics, { trackEvent } from '@redux-beacon/google-analytics'
import {
  setAmount,
  setCurrency,
  startCheckout,
  complete,
  fail,
  cancelCheckout,
  setPaymentMethod
} from '../slices/donations'
import setPatronCookie from './patron-cookie'
import over from 'lodash/over'

// GA default Events category
const EVENT_CATEGORY = 'Donations'
// Static list of handled events
const CURRENCY_CHANGED = 'Currency changed'
// Amount changed event
const AMOUNT_CHANGED = 'Amount changed'
// Payment started event
const CHECKOUT_STARTED = 'Checkout Started'
// Payment canceled event
const CHECKOUT_CANCELED = 'Checkout Canceled'
// Completed event, goal attached to this event
const CHECKOUT_COMPLETED = 'Checkout Completed'
// Failed for a server side error, should never happen
const CHECKOUT_FAILED = 'Checkout Failed'
// Change payment method
const PAYMENT_METHOD_CHANGED = 'Changed payment method'

const ga = GoogleAnalytics()

// Events to trigger after action is triggered
const eventsMap = {
  [setAmount]: trackEvent(action => ({
    category: EVENT_CATEGORY,
    action: AMOUNT_CHANGED,
    label: `Amount changed from ${action.payload.source}`,
    value: action.payload.amount
  })),
  [setCurrency]: trackEvent(action => ({
    category: EVENT_CATEGORY,
    action: CURRENCY_CHANGED,
    label: `Currency changed to ${action.payload}`
  })),
  [setPaymentMethod]: trackEvent(({payload}) => ({
    category: EVENT_CATEGORY,
    action: PAYMENT_METHOD_CHANGED,
    label: `Payment method changed to ${payload.id}`
  })),
  [startCheckout]: trackEvent(({payload}) => ({
    category: EVENT_CATEGORY,
    action: CHECKOUT_STARTED,
    label: `${payload.source}: ${payload.period} subscription in ${payload.currency}`,
    value: payload.amount
  })),
  [complete]: over(
    // Track the event
    trackEvent(({payload}) => ({
      category: EVENT_CATEGORY,
      action: CHECKOUT_COMPLETED,
      label: `${payload.source}: ${payload.period} subscription in ${payload.currency}`,
      value: payload.amount
    })),
    // Call legacy code
    setPatronCookie
  ),
  [cancelCheckout]: trackEvent(({payload}) => ({
    category: EVENT_CATEGORY,
    action: CHECKOUT_CANCELED,
    label: `${payload.source}: ${payload.period} subscription in ${payload.currency}`,
    value: payload.amount
  })),
  [fail]: trackEvent(({payload}) => ({
    category: EVENT_CATEGORY,
    action: CHECKOUT_FAILED,
    label: `${payload.source}: ${payload.period} subscription in ${payload.currency}`,
    value: payload.amount
  }))
}

export const analyticsMiddleware = createMiddleware(eventsMap, ga)
