import { combineReducers } from 'redux'
import donations from './slices/donations'

const rootReducer = combineReducers({
  donations
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
