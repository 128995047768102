import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import axios from 'axios'
import includes from 'lodash/includes'
import values from 'lodash/values'
import remove from 'lodash/remove'
import api from '../../lib/api'
import qs from 'qs'

const COUNTRY_TO_CURRENCY = {
    MX: 'MXN',
    CO: 'COP',
    AR: 'ARS',
    PL: 'PLN',
    BR: 'BRL'
}

// List of countries that should use USD as default currency
export const USD_COUNTRIES = [
    'US', 'CA', 'BZ', 'CR', 'SV', 'GT',
    'HN', 'NI', 'PA', 'CA', 'RA', 'BR',
    'CL', 'CR', 'CU', 'DO', 'EC', 'SV', 'GT', 'GY', 'HT',
    'HN', 'PY', 'PE', 'PR', 'UY', 'VE'
]

export const AMOUNTS_TABLE = {
    EUR: {month: [3, 5, 9], single: [30, 75, 150]},
    USD: {month: [5, 9, 15], single: [30, 75, 150]},
    PLN: {month: [10, 20, 50], single: [20, 50, 100]},
    BRL: {month: [10, 20, 40], single: [125, 300, 600]},
    MXN: {month: [60, 100, 200], single: [600, 1500, 3000]},
    ARS: {month: [150, 250, 450], single: [1500, 3500, 7500]},
    COP: {month: [10000, 20000, 30000], single: [100000, 250000, 500000]}
}

const changeCurrency = (state, action) => {
    if (includes(state.availableCurrencies, action.payload)) {
        state.currency = action.payload
        state.monthlyValues = AMOUNTS_TABLE[action.payload]['month']
        state.singleValues = AMOUNTS_TABLE[action.payload]['single']
    }
}

export type Currency = 'EUR' | 'USD' | 'PLN' | 'BRL' | 'MXN' | 'ARS' | 'COP'
export type Period = 'single' | 'month'
export type Campaign = {
    name?: string
    id?: number
}

export type PaymentMethod = {
    /**
     * Payment method identifier
     */
    id: 'stripe' | 'p24' | 'sepa' | 'paypal'
    /**
     * Whether is active for current country/ip
     */
    active: boolean
    /**
     * List of supported currencies
     */
    supportedCurrencies: Array<Currency>
    /**
     * Http address of payment method icon
     */
    iconUrl: string
    /**
     * Supports monthly donations
     */
    monthlyAllowed: boolean
    /**
     * Is masterpass
     */
    masterpass: boolean
    /**
     * Is visa checkout
     */
    visaCheckout: boolean
    /**
     * Is only euro supported
     */
    onlyEuro: boolean
    /**
     * Is automatically selected
     */
    automatic: boolean
}

export type CountryDetectedPayload = {
    countryCode: string
    currency: Currency
    ip: string
}

export type CheckoutPayload = {
    source: string,
    amount: number,
    currency: Currency,
    period: Period,
    loading?: boolean
}

export type SetAmountPayload = {
    amount: number
    period: Period
    source?: string
}

type DonationSlice = {
    /**
     * Loader flag
     */
    loading?: boolean
    /**
     * Payment method
     */
    paymentMethod?: PaymentMethod
    /**
     * Selected currency, defaults to EUR
     */
    currency: Currency
    /**
     * User email address
     */
    email?: string
    /**
     * List of available currencies in current country (dynamic)
     */
    availableCurrencies: Array<Currency>
    /**
     * Selected amount
     */
    amount?: number
    /**
     * Selected period
     */
    period?: Period
    /**
     * Selection for monthly values
     */
    monthlyValues: Array<number>
    /**
     * Selection for single values
     */
    singleValues: Array<number>
    /**
     * User's ip address
     */
    ipAddress?: string
    /**
     * User's detected country code
     */
    countryCode?: string
    /**
     * Whether user has completed checkout
     */
    complete: boolean
    /**
     * Whether checkout went well
     */
    success: boolean
    /**
     * Whether checkout went wrong
     */
    failed: boolean
    /**
     * Donations campaign
     */
    campaign: Campaign
    /**
     * Used to show currency alert
     */
    currencyAlert?: boolean
    /**
     * Used to show that a server error occurred
     */
    serverError?: boolean
}

const initialState: DonationSlice = {
    currency: 'EUR',
    availableCurrencies: ['EUR', 'USD'],
    monthlyValues: [3, 5, 9],
    singleValues: [30, 75, 150],
    complete: false,
    success: false,
    failed: false,
    campaign: {}
}

const donationsSlice = createSlice({
    name: 'donations',
    initialState: initialState,
    reducers: {
        setEmail(state, {payload}: PayloadAction<string>) {
            state.email = payload
        },
        setCurrency(state, action: PayloadAction<Currency>) {
            changeCurrency(state, action)
        },
        setAmount(state, {payload: {amount, period}}: PayloadAction<SetAmountPayload>) {
            state.amount = amount
            state.period = period
        },
        setPaymentMethod(state, action : PayloadAction<PaymentMethod>) {
            state.paymentMethod = action.payload
            if (!action.payload.monthlyAllowed && state.period === 'month') {
                // reset period and amount
                state.period = null
                state.amount = null
            }
            // sepa only accepts euros
            if (action.payload.onlyEuro && state.currency !== 'EUR') {
                state.currency = 'EUR'
                state.currencyAlert = true
            }
            // check for supported currencies
            if (action.payload.supportedCurrencies && action.payload.supportedCurrencies.length && !includes(action.payload.supportedCurrencies, state.currency)) {
                state.currency = 'EUR'
                state.currencyAlert = true
            }
        },
        setAvailableCurrencies(state, action: PayloadAction<Array<Currency>>) {
            state.availableCurrencies = action.payload
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        countryDetected(state, action: PayloadAction<CountryDetectedPayload>) {
            // First set country code
            state.countryCode = action.payload.countryCode
            // set ip address if given from backend
            state.ipAddress = action.payload.ip
            // then manage currencies
            if (includes(USD_COUNTRIES, action.payload.countryCode)) state.currency = 'USD'
            // default currency management
            let defaultCurrencyForCountry = COUNTRY_TO_CURRENCY[action.payload.countryCode]
            // detected state has a default currency
            if (defaultCurrencyForCountry && includes(state.availableCurrencies, defaultCurrencyForCountry)) {
                changeCurrency(state, {payload: defaultCurrencyForCountry})
            }
            // Remove all country currencies from available currencies if country is not that one
            remove(state.availableCurrencies, c => c !== defaultCurrencyForCountry && includes(values(COUNTRY_TO_CURRENCY), c))
        },
        resetCurrencyAlert(state) {
            state.currencyAlert = false
        },
        complete(state, _action : PayloadAction<CheckoutPayload>) {
            state.complete = true
            state.success = true
            state.loading = false
        },
        fail(state, _action : PayloadAction<CheckoutPayload>) {
            state.complete = true
            state.failed = true
            state.loading = false
        },
        serverError(state, {payload}) {
            state.serverError = payload
            state.loading = false
        },
        acknowledgeError(state) {
            state.serverError = false
        },
        startCheckout(state, {payload}: PayloadAction<CheckoutPayload>) {
            // only used to track checkout in GA
            state.loading = payload.loading
        },
        cancelCheckout(state, _action: PayloadAction<CheckoutPayload>) {
            // only used to track checkout in GA
            state.loading = false
        },
        setCampaign(state, {payload}: PayloadAction<Campaign>) {
            state.campaign = payload
        }
    }
})

// Extract and export each action creator by name
export const {
    setLoading, setAvailableCurrencies, setCurrency, setPaymentMethod, setAmount, countryDetected, setCampaign,
    setEmail, resetCurrencyAlert, fail, complete, startCheckout, cancelCheckout, serverError, acknowledgeError
} = donationsSlice.actions
// Export the reducer, either as a default or named export
export default donationsSlice.reducer

/**
 * Async action that load all needed data and dispatch current actions
 * @returns an async action creator
 */
export const loadFormData = () => dispatch => {
    axios.all([api.get<Array<Currency>>('/available_currencies'), api.get<CountryDetectedPayload>('/currency_info')])
        .then(axios.spread((currencies: Array<Currency>, countryInfo: CountryDetectedPayload) => {
            // Both requests are now complete
            dispatch(setAvailableCurrencies(currencies))
            dispatch(countryDetected(countryInfo))
        }))
        .finally(() => dispatch(setLoading(false)))
    // Load campaign if qs present
    let {campaign} = qs.parse(window.location.search, {ignoreQueryPrefix: true})
    if (campaign) {
        api.get<Campaign>('/campaign_data', {params: {name: campaign}})
            .then((data: Campaign) => dispatch(setCampaign(data)))
    }
}
