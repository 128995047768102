/**
 * Custom redux action listener integrated with GTM
 * @see https://rangle.gitbook.io/redux-beacon/index/google-tag-manager
 */
import GoogleTagManager from '@redux-beacon/google-tag-manager';
import {createMiddleware} from "redux-beacon";
import {
    complete,
    fail, CheckoutPayload
} from '../slices/donations'
import {PayloadAction} from "@reduxjs/toolkit";
import {EventsMap} from "redux-beacon/src/types";
import sha256 from 'crypto-js/sha256';
import {RootState} from "../reducers";

/**
 * Final donation outcome values
 */
export type Outcome = 'success' | 'fail'

// Event to send to GTM wrapper
type GtmEvent = {
    event: string,
    donation_amount: number,
    donation_type: 'unique' | 'monthly',
    user_id: string
    result: Outcome
}

// Tracked GTM events
// see: https://docs.google.com/spreadsheets/d/1RUNCR4xFboZa7ovUxTXeIQWxKbsH7NWvGvfU_0wzL-w/edit?ts=60d5dabf#gid=735857027
const EVENT_NAME = 'donation_confirmation'

const payloadToEvent = (result: Outcome) => ({payload}: PayloadAction<CheckoutPayload>, prevState: RootState): GtmEvent => ({
    event: EVENT_NAME,
    donation_amount: payload.amount,
    donation_type: payload.period == 'month' ? 'monthly' : 'unique',
    user_id: prevState.donations.email ? sha256(prevState.donations.email).toString() : null,
    result: result
});

const gtm = GoogleTagManager();

// Events to trigger after action is triggered
const eventsMap: EventsMap = {
    // @ts-ignore need to be written in this way
    [complete]: payloadToEvent('success'),
    // @ts-ignore need to be written in this way
    [fail]: payloadToEvent('fail')
}

export const gtmMiddleware = createMiddleware(eventsMap, gtm);
