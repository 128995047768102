import * as React from 'react'
import {Row, Tabs, Tab, Table, OverlayTrigger, Popover, Label, ListGroup, ListGroupItem} from 'react-bootstrap';
import {I18nLocale} from "../globals";
import {groupBy, truncate} from 'lodash'
import {makeUseAxios} from "axios-hooks";
import applyConverters from 'axios-case-converter'
import axios from "axios";
import LoaderOverlay from "./LoaderOverlay";
import {Fragment} from "react";
import Fa from 'react-fontawesome';

interface SendgridCampaignsProps {
    token: string;
}

const axiosConfig = {
    baseURL: '/',
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
}

let JWTtoken;

const jwtApi = applyConverters(axios.create(axiosConfig));

// Add JWT authentication
jwtApi.interceptors.request.use(config => {
    config.headers.Authorization = JWTtoken ? `Bearer ${JWTtoken}` : '';
    return config;
});

/**
 * Api instance that works with hooks api, use another axios instance without data interceptor
 */
export const useJwtApi = makeUseAxios({axios: jwtApi});

export type EmailCampaign = {
    id: number,
    name: string,
    locale: I18nLocale,
    sender: string,
    listId: string,
    suppressionGroupId?: number,
    postId: number,
    contentUrl: string,
    deliverAt?: string,
    schedule?: string,
    nextTime?: string,
    categories: Array<string>,
    scheduledJobReference?: string,
    deliveriesCount: number,
    createdAt: string,
    updatedAt: string,
}

export type EmailCampaignDelivery = {
    id: number
    subject: string
    campaignId: number
    singleSendId: string
    senderId: number
    testEmails: Array<string>
    createdAt: string
    updatedAt: string
}

function CampaignPopover({campaign}: { campaign: EmailCampaign }) {
    const popover = (
        <Popover id={`popover-campaign-${campaign.id}`} title={campaign.name} style={{minWidth: '600px'}}>
            <dl className="dl-horizontal">
                <dt>List ID</dt>
                <dd><a href={`https://mc.sendgrid.com/contacts/lists/${campaign.listId}`}>{campaign.listId}</a></dd>
                <dt>Sender</dt>
                <dd>{campaign.sender}</dd>
                <dt>Suppression group</dt>
                <dd>{campaign.suppressionGroupId}</dd>
                <dt>Categories</dt>
                <dd>{campaign.categories.map(c => <Fragment key={c}><Label>{c}</Label>{' '}</Fragment>)}</dd>
                {campaign.nextTime && <>
                  <dt>Next Time</dt>
                  <dd>{campaign.nextTime}</dd>
                </>}
            </dl>
        </Popover>
    )


    return <>
        <a href={campaign.contentUrl} target="_blank">{campaign.name}</a>{' '}
        <OverlayTrigger placement="right" overlay={popover} rootClose>
            <Fa name="info-circle" style={{cursor: 'pointer'}}/>
        </OverlayTrigger>
    </>
}

function DeliverySubject({delivery}: { delivery: EmailCampaignDelivery }) {
    return <strong>
        {delivery.testEmails.length > 0 && <Fa name="flask"/>}{' '}
        {truncate(delivery.subject, {length: 30})}
    </strong>;
}

function TestEmails({delivery}: { delivery: EmailCampaignDelivery }) {
    if (delivery.testEmails.length == 0) return null;
    return <p>
        Test emails sent to:
        <ul>
            {delivery.testEmails.map(e => <li key={e}>{e}</li>)}
        </ul>
    </p>
}

function DeliveriesPopover({campaign}: { campaign: EmailCampaign }) {
    const [{
        data: deliveries = [],
        loading
    }, loadDeliveries] = useJwtApi<Array<EmailCampaignDelivery>>(
        {
            url: `/email_campaigns/${campaign.postId}/deliveries`,
            params: {
                locale: campaign.locale
            }
        }, {
            manual: true
        }
    );

    const popover = (
        <Popover id={`popover-deliveries-${campaign.id}`}
                 title={`Last ${deliveries.length} deliveries`}
                 style={{minWidth: '300px'}}>

            {loading &&
              <div style={{textAlign: "center", padding: "20px 0"}}>
                <Fa name='refresh' spin size='3x'/>
              </div>
            }
            {!loading &&
              <ListGroup>
                  {deliveries.map(delivery => (
                      <ListGroupItem key={delivery.id} header={<DeliverySubject delivery={delivery}/>} target="_blank"
                                     href={`https://mc.sendgrid.com/single-sends/${delivery.singleSendId}/stats`}>
                          <TestEmails delivery={delivery}/>{' '}
                          <small>Created at {delivery.createdAt}</small>
                      </ListGroupItem>
                  ))}
              </ListGroup>
            }
        </Popover>
    )

    return <OverlayTrigger trigger="click" placement="bottom" overlay={popover} onEnter={loadDeliveries}>
        <Fa name="info-circle" style={{cursor: 'pointer'}}/>
    </OverlayTrigger>
}

function CampaignTable({campaigns}: { campaigns: Array<EmailCampaign>, locale: I18nLocale }) {
    return <Table striped bordered responsive hover>
        <thead>
        <tr>
            <th>ID</th>
            <th>Post ID</th>
            <th>Name</th>
            <th>Sender</th>
            <th>Schedule</th>
            <th>Deliveries</th>
        </tr>
        </thead>
        <tbody>
        {campaigns.map(campaign => (<tr key={campaign.id}>
            <td>{campaign.id}</td>
            <td>{campaign.postId}</td>
            <td><CampaignPopover campaign={campaign}/></td>
            <td>{campaign.sender}</td>
            <td>{campaign.schedule || campaign.deliverAt || '-'}</td>
            <td>{campaign.deliveriesCount} {campaign.deliveriesCount > 0 &&
              <DeliveriesPopover campaign={campaign}/>}</td>
        </tr>))}
        </tbody>
    </Table>;
}

export default function SendgridCampaigns({token}: SendgridCampaignsProps) {
    JWTtoken = token;
    const [{data: campaigns = [], loading}] = useJwtApi<Array<EmailCampaign>>('/email_campaigns');

    if (loading) return <LoaderOverlay/>;

    // Group campaigns by locale
    const groupedCampaigns = groupBy(campaigns, 'locale');

    // Return a static tab, no pagination
    return (
        <Row>
            <h2>Sendgrid campaigns</h2>

            <Tabs id="emailCampaigns">
                {Object.keys(groupedCampaigns).map(locale =>
                    <Tab key={locale} eventKey={locale} title={locale.toLocaleUpperCase()}>
                        <CampaignTable campaigns={groupedCampaigns[locale]} locale={locale as I18nLocale}/>
                    </Tab>
                )}
            </Tabs>
        </Row>
    );
}
