import React, { Fragment, useEffect } from 'react'
import makeAsyncScript from 'react-async-script'
import property from 'lodash/property'
import every from 'lodash/every'
import once from 'lodash/once'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import api from '../../lib/api'
import PropTypes from 'prop-types'
import { cancelCheckout, complete, startCheckout } from '../../donations-store/slices/donations'
import { useStripe } from '@stripe/react-stripe-js'

const Routes = window.Routes
const I18n = window.I18n
const ReactApp = window.ReactApp
const Promise = window.Promise

// This is static and retrieved from stripe dashboard
const API_KEY = 'BMJI8H86GNC0PSONZ3J721_xo40doKkzQstiHxTeRQzvN2cYI'

function visaCheckoutScriptUrl () {
  return ReactApp.stripeStaging ?
    'https://sandbox-assets.secure.checkout.visa.com/checkout-widget/resources/js/integration/v1/sdk.js' :
    'https://assets.secure.checkout.visa.com/checkout-widget/resources/js/integration/v1/sdk.js'
}

function visaButtonSource () {
  return ReactApp.stripeStaging ?
    'https://sandbox.secure.checkout.visa.com/wallet-services-web/xo/button.png' :
    'https://secure.checkout.visa.com/wallet-services-web/xo/button.png'
}

/**
 * Utility function that build and return a promise
 * @returns {{resolver: *, rejecter: *, promise: Promise<unknown>}}
 */
const makePromise = () => {
  let resolver, rejecter
  return {
    promise: new Promise((resolve, reject) => {
      resolver = resolve
      rejecter = reject
    }),
    resolver,
    rejecter
  }
}

// Shared promise that will be resolved when stripe payment intent completes
const {promise: visaPaymentSuccess, resolver: visaResolver} = makePromise()

// Init called only once with any value, actual payment amount is given in stripe call
// This function is automatically called by visa checkout SDK on load
// see https://stripe.com/docs/visa-checkout
window.onVisaCheckoutReady = once((V, amount = 10, currency = 'EUR') => {
  // SDK init
  window.V.init({
    apikey: API_KEY,
    paymentRequest: {
      subtotal: Number(amount).toFixed(2).toString(),
      currencyCode: currency
    }
  })
  // Configure callbacks for Visa payment success
  window.V.on('payment.success', visaResolver)
})

function completeCardPayment (intentPromise) {
  return Promise.all([
    visaPaymentSuccess,
    intentPromise.then(property('clientSecret'))]
  )
}

VisaCheckoutButtonWrapper.propTypes = {
  V: PropTypes.shape({
    on: PropTypes.func.isRequired
  })
}

function VisaCheckoutButtonWrapper ({V}) {
  const email = useSelector(property('donations.email'))
  const campaign = useSelector(property('donations.campaign.name'))
  const period = useSelector(property('donations.period'))
  const dispatch = useDispatch()
  const stripe = useStripe()
  const visaParams = useSelector(state => ({
    email: email,
    locale: I18n.locale,
    amountCents: state.donations.amount * 100,
    amountCurrency: state.donations.currency,
    period: state.donations.period
  }), shallowEqual)

  // configure visa checkout callback as soon as V and stripe are ready
  // useEffect(() => {setVisaOptions(V)}, [visaParams.amountCents, visaParams.amountCurrency])
  useEffect(() => {
    if (V && V.on) V.on('payment.cancel', () => dispatch(cancelCheckout({
      source: 'VisaCheckout',
      amount: visaParams.amountCents / 100,
      currency: visaParams.amountCurrency,
      period: visaParams.period,
    })))
  }, [V])

  const sendPayment = () => {
    dispatch(startCheckout({
      source: 'VisaCheckout',
      amount: visaParams.amountCents / 100,
      currency: visaParams.amountCurrency,
      period: visaParams.period,
      loading: true
    }))
    // Run setup intent and visa promise
    completeCardPayment(api.post(Routes.donations_payment_intent_path(), {
      paymentRequest: {...visaParams, campaign: campaign}
    })).then(([payment, clientSecret]) => stripe.confirmCardPayment(clientSecret, {
        payment_method: {card: {visa_checkout: {callid: payment.callid}}}
      })
    ).then(intent => {
      // Payment intent is completed
      dispatch(complete({
        source: 'VisaCheckout',
        amount: intent.amount / 100,
        currency: intent.currency,
        period: visaParams.period
      }))
    })
  }

  return <Fragment>
    <input type='image' disabled={!every(visaParams) || period !== 'single'}
           className='v-button' src={visaButtonSource()}
           alt='Visa Checkout' onClick={sendPayment}/>
  </Fragment>
}

export const VisaCheckoutButton = makeAsyncScript(
  visaCheckoutScriptUrl,
  {globalName: 'V'}
)(VisaCheckoutButtonWrapper)
