import React, { Fragment, useEffect, useState } from 'react'
import {
  Button,
  ButtonGroup,
  Col, Dropdown,
  FormControl,
  MenuItem,
  Row
} from 'react-bootstrap'
import PropTypes from 'prop-types'
import { setAmount, setCurrency } from '../../donations-store/slices/donations'
import { connect } from 'react-redux'

const I18n = window.I18n

AmountSelector.propTypes = {
  currentAmount: PropTypes.number,
  currentPeriod: PropTypes.string,
  period: PropTypes.string.isRequired,
  choices: PropTypes.arrayOf(PropTypes.number).isRequired,
  currency: PropTypes.string.isRequired,
  setCurrency: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  availableCurrencies: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
}

Currency.propTypes = {
  currency: PropTypes.string.isRequired
}

function Currency ({currency}) {
  return <Fragment>
    <i className={`fa fa-fw fa-cr-${currency.toString().toLowerCase()}`}/>
    {currency}
  </Fragment>
}

/**
 * @return {React.node,null}
 */
function AmountSelector ({currentPeriod, currentAmount, period, choices, currency, availableCurrencies, setCurrency, setAmount}) {
  // do not render until currencies available
  if (!availableCurrencies.length) return null
  // hold number input value
  const [inputValue, setInputValue] = useState('')
  useEffect(() => {
    let newValue = period !== currentPeriod || currentAmount !== Number(inputValue) ? '' : inputValue
    setInputValue(newValue.toString())
  }, [currentAmount, currentPeriod])

  const valueChanged = (e) => {
    chooseAmount('field')(e)
    setInputValue(e.target.value)
  }

  const toSuffix = (amount) => amount > 1000 ? `${amount / 1000}K` : amount
  const isActive = (v) => Number(v) === currentAmount && period === currentPeriod
  const chooseAmount = (source) => (e) => setAmount({
    amount: Number(e.target.value || e.target.getAttribute('value')),
    period: period,
    source
  })
  const selectCurrency = (key) => setCurrency(key)

  return <Row className={`amount-selector row-no-gutters ${period}`}>

    <Col md={6} xs={6}>
      {/* Predefined amounts */}
      <ButtonGroup justified>
        {choices.map((v) => (
          <Button href='#' key={v} value={v}
                  bsStyle={isActive(v) ? 'primary' : 'default'}
                  onClick={chooseAmount('button')}>
            {toSuffix(v)}
          </Button>
        ))}
      </ButtonGroup>
    </Col>
    <Col md={4} xs={3}>
      {/* Free form amount */}
      <FormControl type="number" min={1} id={`${period}-amount`} onChange={valueChanged} value={inputValue}
                   placeholder={I18n.t('donations.home.stripe_form_v2.or_enter_custom_amount')}/>
      {/* Currency selector */}
    </Col>
    <Col md={2} xs={3}>
      <Dropdown vertical block onSelect={selectCurrency} id={`currency-selector-${period}`}>
        <Dropdown.Toggle block>
          <Currency currency={currency}/>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {availableCurrencies.filter(c => c !== currency).map((currency) => (
            <MenuItem eventKey={currency} key={currency}>
              <Currency currency={currency}/>
            </MenuItem>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Col>
  </Row>
}

export default connect(
  state => ({
    currency: state.donations.currency,
    currentAmount: state.donations.amount,
    currentPeriod: state.donations.period,
    availableCurrencies: state.donations.availableCurrencies
  }),
  {setAmount, setCurrency}
)(AmountSelector)