import * as React from 'react'
import {Fragment} from 'react'
import {useSelector} from 'react-redux'
import {setEmail} from '../../donations-store/slices/donations'
import noop from 'lodash/noop'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {FormItem} from './formik/FormItem'
import {useDonationsAppDispatch} from '../../donations-store'

const I18n = window.I18n

type EmailInputValues = {
    email: string
}

export function EmailInput() {
    const dispatch = useDonationsAppDispatch();
    const paymentMethod = useSelector(s => s.donations.paymentMethod?.id)

    // Only render if stripe or p24
    if (paymentMethod !== 'stripe' && paymentMethod !== 'p24') return null

    const validationsSchema = Yup.object<EmailInputValues>({
        email: Yup.string()
            .email(I18n.t('activemodel.errors.messages.invalid_email_address'))
            .required(I18n.t('errors.messages.blank')),
    })

    function emailChanged({field, meta}) {
        meta.error ? dispatch(setEmail('')) : dispatch(setEmail(field.value))
    }

    return <Formik<EmailInputValues>
        onSubmit={noop}
        initialValues={{email: ''}}
        validationSchema={validationsSchema}>

        <Fragment>
            <FormItem horizontal name='email' label='Email' type='email' onBlur={emailChanged}/>
        </Fragment>

    </Formik>
}
