import { Field, useField } from 'formik'
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock, InputGroup } from 'react-bootstrap'
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import over from 'lodash/over'

export const FormItem = ({horizontal, label, onChange, onBlur, prefix, suffix, ...props}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props)
  // randomize IDs to avoid name clash in the same page
  const randomId = `${field.name}-${Math.random().toString(36).substring(2)}`

  // Enrich original function with improved versions
  if (onChange) field.onChange = over(field.onChange, () => onChange({field, meta}))
  if (onBlur) field.onBlur = over(field.onBlur, () => onBlur({field, meta}))

  return <FormGroup controlId={randomId} validationState={meta.touched ? meta.error ? 'error' : 'success' : null}>

    {horizontal ? (
        <Fragment>
          <Col componentClass={ControlLabel} sm={2}>
            {label}
          </Col>

          <Col sm={10}>
            {(prefix || suffix) ?
              (<InputGroup>
                  {prefix && <InputGroup.Addon>{prefix}</InputGroup.Addon>}
                  <Field as={FormControl} {...field} {...props}/>
                  {suffix && <InputGroup.Addon>{suffix}</InputGroup.Addon>}
                </InputGroup>
              ) :
              (<Field as={FormControl} {...field} {...props}/>)
            }
            {suffix || <FormControl.Feedback/>}
            {meta.touched && meta.error && <HelpBlock>{meta.error}</HelpBlock>}
          </Col>
        </Fragment>)
      : (
        <Fragment>
          {label && <ControlLabel>{label}</ControlLabel>}

          <Fragment>
            {(prefix || suffix) ?
              (<InputGroup>
                  {prefix && <InputGroup.Addon>{prefix}</InputGroup.Addon>}
                  <Field as={FormControl} {...field} {...props}/>
                  {suffix && <InputGroup.Addon>{suffix}</InputGroup.Addon>}
                </InputGroup>
              ) :
              (<Field as={FormControl} {...field} {...props}/>)
            }
            {/* Show feedback only if suffix is not given */}
            {suffix || <FormControl.Feedback/>}
            {/* Error hint */}
            {meta.touched && meta.error && <HelpBlock>{meta.error}</HelpBlock>}
          </Fragment>
        </Fragment>
      )}

  </FormGroup>
}

FormItem.propTypes = {
  horizontal: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  prefix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  suffix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ])
}
