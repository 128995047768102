import axios from 'axios'
import applyConverters from 'axios-case-converter'
import * as Qs from 'qs'
import property from 'lodash/property'
import { makeUseAxios } from 'axios-hooks'

const axiosConfig = {
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  paramsSerializer: function (params) {
    return Qs.stringify(params, {arrayFormat: 'brackets'})
  }
}
// see https://github.com/mpyw/axios-case-converter
const api = applyConverters(axios.create(axiosConfig))

api.interceptors.response.use(property('data'))

export default api

/**
 * Api instance that works with hooks api, use another axios instance without data interceptor
 */
export const useApi = makeUseAxios({axios: applyConverters(axios.create(axiosConfig))});
