import {shallowEqual, useSelector} from 'react-redux'
import property from 'lodash/property'
import {AnalyticsCampaign, campaignSelector} from '../components/donations/payment-buttons/shared'
import {Currency, Period} from "./slices/donations";

export function useCurrency(): Currency {
    return useSelector(property('donations.currency'))
}

export function useAmount(): number {
    return useSelector(property('donations.amount'))
}

export function usePeriod(): Period {
    return useSelector(property('donations.period'))
}

export function useCampaign(): AnalyticsCampaign {
    return useSelector(campaignSelector, shallowEqual)
}
