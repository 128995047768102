import * as React from 'react'
import {useSelector} from 'react-redux'
import {resetCurrencyAlert} from '../../donations-store/slices/donations'
import {Alert} from 'react-bootstrap'
import {useDonationsAppDispatch} from "../../donations-store";

const I18n = window.I18n

/**
 * @return {React.node, null}
 */
export function CurrencyAlert() {
    const dispatch = useDonationsAppDispatch();
    const currencyAlert = useSelector(s => s.donations.currencyAlert)
    if (!currencyAlert) return null
    return <Alert bsStyle="warning" onDismiss={() => dispatch(resetCurrencyAlert())}>
        <strong>{I18n.t('donations.warning')}</strong> {I18n.t('donations.currency_alert')}
    </Alert>
}
