import * as React from 'react'
import AsyncSelect from 'react-select/async'
import {components} from 'react-select'
import {useState} from 'react'
import property from 'lodash/property'
import filter from 'lodash/filter'
import find from 'lodash/find'
import {PaymentMethod, setPaymentMethod} from '../../donations-store/slices/donations'
import api from '../../lib/api'
import qs from 'qs'
import {useDonationsAppDispatch} from "../../donations-store";

const I18n = window.I18n

// Parse query string to see if payment method is preselected
let queryString = qs.parse(window.location.search, {ignoreQueryPrefix: true})

type PaymentOptionProps = any & {
    data: Pick<PaymentMethod, 'id' | 'iconUrl'>
}

function PaymentOption({data, ...props}: PaymentOptionProps) {
    return <components.Option {...props}>
    <span className={`${data.id}-payment-method`}>
      <span className='image-holder'>
        <img src={data.iconUrl} alt={data.id} className={`payment-type ${data.id}`}/>
      </span>
        {data.text}
    </span>
    </components.Option>
}


function PaymentMethodSelector() {
    const [defaultValue, setDefaultValue] = useState<PaymentMethod>()
    const dispatch = useDonationsAppDispatch();
    // Invoked after data load to preselect value and trigger redux state change
    const initialize = (data: Array<PaymentMethod>) => {
        let chosen = find(data, {id: queryString.payment_type}) || data[0]
        choose({...chosen, automatic: true}) // to distinguish from user selected method
        return data
    }

    const choose = (value) => {
        dispatch(setPaymentMethod(value))
        setDefaultValue(value)
    }

    // /:locale/payment_methods
    const loadOptions = () => api.get<Array<PaymentMethod>>(`${I18n.locale}/payment_methods`)
        .then(data => filter(data, 'active'))
        .then(initialize)

    return <AsyncSelect<PaymentMethod>
        value={defaultValue}
        components={{Option: PaymentOption}}
        cacheOptions
        defaultOptions
        onChange={choose}
        loadOptions={loadOptions}
        getOptionLabel={property('text')}
        getOptionValue={property('id')}/>
}

export default PaymentMethodSelector
