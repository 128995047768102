import * as React from 'react'
import {Col} from 'react-bootstrap'
import DonationsForm from './DonationsForm'
import {SuccessPanel} from './SuccessPanel'
import {FailurePanel} from './FailurePanel'
import {useSelector} from 'react-redux'
import pick from 'lodash/pick'

export default function Layout() {
    const {complete, success, failed} = useSelector((state) => pick(state.donations, ['complete', 'success', 'failed']))
    return <Col md={12} mdOffset={0} smOffset={1} sm={10}>
        {success && <SuccessPanel/>}
        {failed && <FailurePanel/>}
        {complete || <DonationsForm/>}
    </Col>
}
