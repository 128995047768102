import * as React from 'react'
import {useStripe} from '@stripe/react-stripe-js'
import {shallowEqual, useSelector} from 'react-redux'
import {serverError, setLoading, startCheckout} from '../../../donations-store/slices/donations'
import api from '../../../lib/api'
import {Button} from 'react-bootstrap'
import every from 'lodash/every'
import {FaIcon} from '../FaIcon'
import {stateSelector} from './shared'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {useDonationsAppDispatch} from "../../../donations-store";

const I18n = window.I18n

export function CardPaymentButton() {
    const stripe = useStripe()
    const {executeRecaptcha} = useGoogleReCaptcha()
    const dispatch = useDonationsAppDispatch()
    const sessionParams = useSelector(stateSelector, shallowEqual)
    const campaign = useSelector(s => s.donations.campaign.name)
    // Button in enabled when form filled
    const buttonEnabled = every(sessionParams)

    const doCardPayment = () => {
        dispatch(startCheckout({
            source: 'Stripe',
            amount: sessionParams.amountCents / 100,
            currency: sessionParams.currency,
            period: sessionParams.period
        }))
        dispatch(setLoading(true))
        // Get a fresh V3 captcha token, then initiate payment
        // Starting stripe payment and redirect to checkout after intent created
        // see https://stripe.com/docs/payments/checkout/one-time#client-server
        executeRecaptcha('checkout').then(captchaResponse => ({
            sessionRequest: {...sessionParams, ...campaign}, captchaResponse
        })).then(requestParams => api.post(window.Routes.donations_checkout_path(), requestParams)
            .then(data => stripe.redirectToCheckout({sessionId: data.id}))
            .catch(({response: {data, status, headers}}) => dispatch(serverError({data, status, headers})))
        )
    }

    return <>
        <Button onClick={doCardPayment} disabled={!buttonEnabled} bsStyle="primary" bsSize="large" block>
            {I18n.t('donations.home.stripe_form_v2.please_validate_my_choice')} <FaIcon name='credit-card'/>
        </Button>
    </>
}
