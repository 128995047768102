import * as React from 'react'
import { Fragment, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import property from 'lodash/property'
import { cancelCheckout, complete, fail, setLoading, startCheckout } from '../../../donations-store/slices/donations'
import { Alert, Button, ControlLabel, FormGroup, Modal } from 'react-bootstrap'
import { FaIcon } from '../FaIcon'
import { IbanElement, useElements, useStripe } from '@stripe/react-stripe-js'
import api from '../../../lib/api'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import { FormItem } from '../formik/FormItem'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import {StripeIbanElementOptions} from "@stripe/stripe-js";

const I18n = window.I18n

export function SepaButton () {
  const [visible, setVisible] = useState<boolean>(false)
  const modalClose = () => setVisible(false)
  const currency = useSelector(property('donations.currency'))
  const amount = useSelector(property('donations.amount'))
  const period = useSelector(property('donations.period'))
  const buttonEnabled = currency === 'EUR' && amount
  const dispatch = useDispatch()
  const openModal = () => {
    dispatch(startCheckout({
      source: 'Sepa',
      amount: amount,
      currency: currency,
      period: period
    }))
    setVisible(true)
  }

  return <Fragment>
    <Button onClick={openModal} bsStyle="primary" bsSize="large" block disabled={!buttonEnabled}>
      {I18n.t('donations.sepa.pay_with_sepa')} <FaIcon name='university'/>
    </Button>
    <SepaModal visible={visible} close={modalClose}/>
  </Fragment>
}

const IBAN_STYLE = {
  base: {
    // Add your base input styles here. For example:
    fontSize: '16px',
    color: '#32325d'
  }
}

interface SepaModalProps {
  close: () => void
  visible: boolean
}

export function SepaModal ({close, visible} : SepaModalProps) {
  const stripe = useStripe()
  const {executeRecaptcha} = useGoogleReCaptcha()
  const elements = useElements()
  const countryCode = useSelector(property('donations.countryCode'))
  const campaign = useSelector(property('donations.campaign.name'))
  const dispatch = useDispatch()
  const sepaParams = useSelector(state => ({
    locale: I18n.locale,
    amountCents: state.donations.amount * 100,
    amountCurrency: state.donations.currency,
    period: state.donations.period
  }), shallowEqual)
  const [validIban, setValidIban] = useState(false)
  const ibanChanged = (event) => setValidIban(!event.error)

  // Custom styling can be passed to options when creating an Element.
  const ibanElementOptions : StripeIbanElementOptions = {
    style: IBAN_STYLE,
    supportedCountries: ['SEPA']
  }
  // Add country placeholder if known
  if (countryCode) ibanElementOptions.placeholderCountry = countryCode
  // Analytics params
  const analyticsParams = {
    source: 'Sepa',
    amount: sepaParams.amountCents / 100,
    currency: sepaParams.amountCurrency,
    period: sepaParams.period,
  }
  // When user deliberately close sepa window
  const cancel = () => {
    dispatch(cancelCheckout(analyticsParams))
    close()
  }

  const submitSepa = (values) => {
    // Stripe.js has not yet loaded.
    if (!stripe || !elements) return
    // start loader
    dispatch(setLoading(true))
    // Iban mounted element
    const iban = elements.getElement(IbanElement)
    // form data for confirmation in stripe
    let confirmData = {payment_method: {sepa_debit: iban, billing_details: {...values}}}
    executeRecaptcha('payment_intent')
      .then(captchaResponse => ({
        paymentRequest: {email: values.email, ...sepaParams, campaign},
        captchaResponse
      })) // Server request params
      .then(requestParams => api.post(window.Routes.donations_payment_intent_path(), requestParams)) // Get client token from server
      .then(property('clientSecret')) // Extract client secret (from payment intent or setup intent)
      .then(clientSecret => {
        // confirm sepa payment and return it
        if (sepaParams.period === 'single') {
          return stripe.confirmSepaDebitPayment(clientSecret, confirmData)
            .then(({paymentIntent, error}) => error ? window.Promise.reject(error) : paymentIntent)
        } else {
          // confirm debit setup, then create subscription server side and return subscription
          return stripe.confirmSepaDebitSetup(clientSecret, confirmData)
            .then(({setupIntent, error}) =>
              error ? window.Promise.reject(error) :
                executeRecaptcha('create_subscription').then(captchaResponse => ({
                  paymentRequest: {email: values.email, ...sepaParams, campaign},
                  captchaResponse
                }))
                  .then(requestParams => api.post(window.Routes.donations_create_subscription_path(), {
                    ...requestParams, paymentMethod: setupIntent.payment_method
                  }))
            )
        }
      })
      .then((flowResult) => {
        console.log(
          `Completed ${sepaParams.period} payment of`,
          flowResult.amount ? flowResult.amount / 100 : flowResult.plan.amount / 100,
          flowResult.currency ? flowResult.currency : flowResult.plan.currency
        )
        // Show a confirmation message to your customer.
        close()
        dispatch(complete(analyticsParams))
      })
      .catch(error => {
        dispatch(fail(analyticsParams))
        alert(`There was an error processing your request ${JSON.stringify(error)}`)
      })
  }

  const validationsSchema = Yup.object().shape({
    email: Yup.string()
      .email(I18n.t('activemodel.errors.messages.invalid_email_address'))
      .required(I18n.t('errors.messages.blank')),
    name: Yup.string()
      .min(3, I18n.t('errors.messages.too_short.other', {count: 3}))
      .required(I18n.t('errors.messages.blank'))
  })

  return <Modal show={visible} onHide={close}>
    <Formik onSubmit={submitSepa} initialValues={{email: '', name: ''}} validationSchema={validationsSchema}>
      {({isSubmitting, dirty, isValid}) => (
        <Form>
          <Modal.Header closeButton onHide={cancel}>
            <Modal.Title>{I18n.t('donations.sepa.enter_your_sepa_info')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormItem name='name' label={I18n.t('donations.sepa.name')} prefix={<FaIcon name='user'/>}/>

            <FormItem name='email' label={I18n.t('donations.sepa.email')} prefix={<FaIcon name='envelope'/>}/>

            <FormGroup controlId="sepaIban">
              <ControlLabel>{I18n.t('donations.sepa.iban')}</ControlLabel>
              <IbanElement options={ibanElementOptions} onChange={ibanChanged}/>
            </FormGroup>

            <p>{I18n.t('donations.sepa.mandate_acceptance')}</p>

            <Alert bsStyle="warning">
              <strong>{I18n.t('donations.warning')}</strong> {I18n.t('donations.sepa_alert')}
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={cancel}>{I18n.t('donations.p24.cancel')}</Button>
            <Button type='submit' bsStyle="primary" disabled={isSubmitting || !dirty || !isValid || !validIban}>
              {I18n.t('donations.home.stripe_form.donate_now')}
            </Button>
          </Modal.Footer>
        </Form>)}
    </Formik>
  </Modal>
}
