import * as React from 'react'
import Layout from './donations/Layout'
import {Provider} from 'react-redux'
import {store} from '../donations-store'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe, Stripe} from '@stripe/stripe-js'
import {PageLoader} from './donations/PageLoader'
import {GoogleReCaptcha, GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import {noop} from 'lodash'
import {useMemo} from "react";
import {GTMProvider} from '@elgorditosalsero/react-gtm-hook'

/**
 * Return locale for recaptcha
 * @see https://developers.google.com/recaptcha/docs/language
 * @return {string}
 */
function recaptchaLocale() {
    return window.I18n.locale === 'si' ? 'sl' : window.I18n.locale
}

interface DonationsAppProps {
    stripeKey: string
    recaptchaSiteKey: string
    gtmContainerId: string
}

export default function DonationsApp({stripeKey, recaptchaSiteKey, gtmContainerId}: DonationsAppProps) {
    const stripe = useMemo<Promise<Stripe>>(() => loadStripe(stripeKey), [stripeKey])
    return <Provider store={store}>
        <GTMProvider state={{id: gtmContainerId}}>
            <Elements stripe={stripe}>
                <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey} language={recaptchaLocale()}>
                    <Layout/>
                    <PageLoader/>
                    {/* Trigger action for page loading as soon as widget is loaded */}
                    <GoogleReCaptcha action='donationsWidget' onVerify={noop}/>
                </GoogleReCaptchaProvider>
            </Elements>
        </GTMProvider>
    </Provider>
}
