import * as React from 'react'
import {Col, Row} from 'react-bootstrap'
import {MasterPassButton} from './MasterPassButton'
import {useSelector} from 'react-redux'
import {VisaCheckoutButton} from './VisaCheckoutButton'

/**
 * Additional payment methods for stripe
 *
 * @return {null}
 */
export function AdditionalPaymentMethods() {
    let masterpass = useSelector(s => s.donations.masterpass)
    let visaCheckout = useSelector(s => s.donations.visaCheckout)

    if (!(masterpass || visaCheckout)) return null

    return <Col md={6} mdOffset={3} xs={12} className={'text-center'}>
        <Row>
            {masterpass && <div className='additional-button'><MasterPassButton/></div>}
            {visaCheckout && <div className='additional-button'><VisaCheckoutButton/></div>}
        </Row>
    </Col>
}
