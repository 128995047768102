import * as React from 'react'
import { useSelector } from 'react-redux'
import property from 'lodash/property'
import LoaderOverlay from "../LoaderOverlay";

/**
 * @return {null}
 */
export function PageLoader () {
  const loading = useSelector(property('donations.loading'))
  return loading ? <LoaderOverlay/> : null
}
